/* stylelint-disable no-descending-specificity */
@import '../../variables';
@import '../responsive';
@import '../textTypography';

.ant-drawer {
  transform: translateX(0) !important;
  z-index: 1052;

  .advancedSearchCollapse {
    color: $primary;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 0;
    margin-top: 16px;

    @include textTypography(Inter, 16px, normal, 400, 24px, 0, left);
  }

  @include responsive(pc) {
    display: none;
  }

  .ant-drawer-content-wrapper {
    width: 320px !important;

    .ant-drawer-close {
      padding-top: 25px;
    }

    .ant-drawer-header {
      background-color: #00003d;
      border: unset;

      .headerLogo {
        color: white;
      }

      .ant-drawer-close {
        color: #d0d1d6;
      }
    }

    .ant-drawer-body {
      padding: 0;
      overflow-x: hidden;
      background-color: #00003d;
      display: flex;
      flex-direction: column;

      .ant-menu-item--badge {
        display: flex;
        align-items: center;

        .menuItemBadge {
          color: $purple;
          margin-left: 8px;

          @include textTypography(Inter, 12px, normal, 600, 20px, 0, left);
        }
      }

      .ant-menu-item {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #d0d1d6;

        a {
          color: #d0d1d6;
        }

        @include textTypography(Inter, 14px, normal, 400, 22px, 0, left);
      }

      .dropdownMenuTrigger {

        .ant-menu-submenu-title {
          padding-right: 16px;

          &:hover {
            color: #d0d1d6;
          }

          &:active {
            background-color: unset;
          }

          span.ant-menu-title-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            & > span {
              width: unset;
              margin: 0;
              transition: transform 0.4s;
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex: 1;
            }
          }
        }
      }

      .ant-menu-item-active {
        background-color: #00003d;
      }

      .ant-menu {
        background-color: #00003d;

        .ant-menu-submenu {
          color: #d0d1d6;

          i {
            display: none;
          }

          .ant-menu-sub {
            background-color: #030063;

            .ant-menu-item {
              color: #d0d1d6;
              padding-left: 24px !important;
            }
          }
        }

        .ant-menu-item-selected {
          background-color: unset;
          color: #d0d1d6;

          &::after {
            display: none;
          }
        }

        .ant-menu-item-group {

          .ant-menu-item-group-title {
            color: #737585;

            @include textTypography(Inter, 12px, normal, 600, 20px, 0, left);
          }
        }
      }

      .rightMenu {

        li {
          justify-content: center;
          margin-bottom: 16px;

          &.searchMenuTrigger {
            margin-top: 8px;
            height: 56px !important;
            justify-content: flex-start;
            border-top: 1px solid $text-blue-dark-light !important;
            border-bottom: 1px solid $text-blue-dark-light !important;
          }
        }
      }
    }

    .ant-menu-inline .ant-menu-item-group-list .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title {
      padding-right: 16px;
    }

    .ant-menu-inline.ant-menu-root .ant-menu-item> .ant-menu-title-content, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title> .ant-menu-title-content{

      &, & > span:not(.anticon) {
        width: 100%;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
      border: none !important;
    }
  }
}

.primaryDrawer {

  .ant-drawer-header {
    height: 56px;
    background-color: white !important;
    border-bottom: 1px solid #d0d1d6 !important;

    p {
      margin-bottom: 0;
      max-width: 200px;
      cursor: pointer;
      color: $text-blue-dark-light;
      display: flex;
      align-items: center;

      @include textTypography(Inter, 16px, normal, 600, 24px, 0, left);

      svg {
        font-size: 11.84px;
        margin-right: 10px;
      }
    }
  }

  .ant-drawer-body {
    background-color: white !important;

    .ant-menu {
      background-color: white !important;
    }
  }

  .drawerHeaderBtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    width: 100%;
    color: $grey-9;
    font-weight: bold;
  }
}

.ant-btn {
  border-radius: 4px;

  &-primary {
    background: $primary;
    border-color: $primary;

    &:hover,
    &:focus {
      background: $primary-dark;
      border-color: $primary-dark;
    }
  }

  &-ghost {

    &:hover,
    &:focus {
      color: $primary-dark;
      border-color: $primary-dark;
    }
  }

  &-circle {

    &:hover,
    &:focus {
      color: $primary-dark;
      border-color: $primary-dark;
    }
  }
}

.ant-notification {
  z-index: 1061;
}

.ant-radio-group {

  .ant-radio-button-wrapper {

    &:hover,
    &:focus {
      border-color: $primary !important;
    }

    &:focus-within {
      box-shadow: 0 0 0 3px rgb($primary, 8%) !important;
    }

    &.ant-radio-button-wrapper-checked {
      border-color: $primary !important;

      &::before {
        background-color: $primary;
      }
    }
  }
}

.ant-radio-checked {

  .ant-radio-inner {
    border-color: $primary;

    &::after {
      background-color: $primary;
    }
  }

  &:hover {

    .ant-radio-inner {
      border-color: $primary;
    }
  }
}

.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px rgba($primary, 8%);
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: $primary;
}

.ant-input-search {

  .ant-input-search-button {

    &:hover {
      border-color: $primary !important;
    }

    &:focus {
      border-color: $primary !important;
      box-shadow: 0 0 0 2px rgba($primary, 8%) !important;
    }
  }
}

.ant-input {

  &:hover,
  &:focus {
    border-color: $primary !important;
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba($primary, 8%) !important;
  }

  &-number {

    &:hover,
    &:focus {
      border-color: $primary !important;
    }

    &:focus {
      box-shadow: 0 0 0 2px rgba($primary, 8%) !important;
    }

    &.ant-input-number-focused {
      border-color: $primary !important;
      box-shadow: 0 0 0 2px rgba($primary, 8%) !important;
    }
  }
}

.ant-input-affix-wrapper {

  &:hover {
    border-color: $primary !important;
  }

  &:focus {
    border-color: $primary;
    box-shadow: 0 0 0 2px rgba($primary, 8%);
  }

  .ant-input {

    &:focus {
      box-shadow: none !important;
    }
  }
}

.ant-input-affix-wrapper-focused {
  border-color: $primary;
  box-shadow: 0 0 0 2px rgba($primary, 8%);
}

.ant-select-selector {
  border-radius: 4px !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: $primary;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: $primary;
  box-shadow: 0 0 0 2px rgba($primary, 8%);
}

.ant-tabs-nav {

  .ant-tabs-tab {
    padding: 16px 0;

    &:hover {
      color: unset;
    }
  }

  .ant-tabs-tab-active {

    .ant-tabs-tab-btn {
      color: $primary;
      font-weight: bold;
    }
  }

  .ant-tabs-ink-bar {
    background-color: $primary;
  }
}

.ant-pagination {
  margin-bottom: 40px;

  .ant-pagination-item {
    border-radius: 4px;
    border-color: #d0d1d6 !important;

    @media ( max-width: 500px ) {
      margin-right: 4px;
    }

    a {
      @include textTypography(Inter, 14px, normal, 400, unset, 0, center);
    }

    &.ant-pagination-item-active {
      border-color: $primary !important;

      a {
        color: $primary !important;
      }
    }

    &:hover,
    &:focus {

      a {
        color: $primary;
      }
    }

    &:hover {
      border-color: $primary !important;
    }

    &.ant-pagination-item-link {
      border-radius: 4px !important;
      border-color: #d0d1d6;
    }

    &.ant-pagination-item-ellipsis {
      color: #737585;
    }
  }

  &-next,
  &-prev {

    button {
      border-radius: 4px !important;

      &:hover,
      &:focus {
        color: $primary !important;
        border-color: $primary !important;
      }
    }
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: $purple-lightest;
}

.ant-picker {
  border-radius: 4px;

  &:hover {
    border-color: $primary;
  }

  &-focused {
    border-color: $primary;
    box-shadow: 0 0 0 2px rgb($primary, 8%);
  }
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: $primary;
}

.ant-picker-today-btn {
  color: $primary;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid $primary;
}

.ant-spin-dot-item {
  background-color: $primary !important;
}

.ant-spin-nested-loading {
  padding: 24px 0;
}

.ant-modal-root {

  .ant-modal-mask,
  .ant-modal-wrap {
    z-index: 1052;
  }

  .ant-modal-wrap {
    border-radius: 4px;

    .ant-modal-title {
      @include textTypography(Inter, 16px, normal, 600, 24px, 0, left);
    }

    .ant-modal-content {
      border-radius: 4px;

      .ant-modal-header {
        border-radius: 4px 4px 0 0;
      }
    }
  }
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: $primary;
}

.ant-message {
  z-index: 1052;

  .ant-message-notice {

    .ant-message-custom-content {
      display: flex;
      align-items: center;

      p {
        margin-bottom: 0;
      }

      a {
        color: $primary;
      }
    }
  }
}

.ant-anchor {

  .ant-anchor-link {

    .ant-anchor-link-title {

      &:hover,
      &:focus {
        color: $primary;
      }
    }

    &.ant-anchor-link-active {

      .ant-anchor-link-title {
        color: $primary;
      }
    }
  }
}

.ant-anchor-link-title-active {
  color: $primary !important;
}

.ant-carousel {

  .slick-list {

    .slick-slide {
      pointer-events: auto !important;
    }
  }
}

.ant-image-preview-root {

  .ant-image-preview-mask,
  .ant-image-preview-wrap {
    z-index: 1052;
  }
}

textarea.ant-input {
  border-radius: 4px !important;

  &:hover,
  &:focus {
    border-color: $primary !important;
  }

  &:focus {
    box-shadow: 0 0 0 2px rgb($primary, 8%);
  }
}

.ant-switch {

  &.ant-switch-checked {
    background-color: $primary;

    &:focus {
      box-shadow: 0 0 0 2px rgb($primary, 20%);
    }
  }
}

.checklist__carousel {
  $marginWidth: 24px;

  @include responsive(mobile-large) {
    margin: 0 8px;
  }

  @include responsive(tablet) {
    margin: 0 auto;
    width: 717px;
  }

  @include responsive(pc-small) {
    width: 992px -$marginWidth;
  }

  @include responsive(pc) {
    width: 1200px -$marginWidth;
  }

  @include responsive(pc-large) {
    width: 1600px -$marginWidth;
  }

  .slick-arrow {
    background: white !important;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid #d0d1d6;
    z-index: 2;
    color: $text-blue-dark-lightest !important;
    display: flex !important;
    align-items: center;
    justify-content: center;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);

      svg {
        width: 13px !important;
        height: 13px !important;
      }
    }
  }

  .slick-prev {
    display: none !important;

    @include responsive(mobile-large) {
      display: flex !important;
      left: 0;
    }

    span {
      margin-right: 2px;
    }
  }

  .slick-next {
    display: none !important;

    @include responsive(mobile-large) {
      display: flex !important;
      right: 0;
    }

    span {
      margin-left: 2px;
    }
  }

  .slick-track {
    display: flex !important;
    padding-bottom: 5px;

    @include responsive(tablet) {
      width: 348px;
    }

    @include responsive(pc-small) {
      width: 299px;
    }

    @include responsive(pc) {
      width: 368px;
    }

    .slick-slide {
      height: inherit !important;

      & > div {
        height: 100%;
      }
    }
  }
}

$xs: 576px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1600px;
$breakpoints: (
  'xs': ( max-width: $xs),
  'sm': ( min-width: $sm),
  'md': ( min-width: $md),
  'lg': ( min-width: $lg),
  'xl': ( min-width: $xl),
  'xxl': ( min-width: $xxl),
) !default;

/// Mixin to manage responsive breakpoints
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin responsive($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect( map-get( $breakpoints, $breakpoint ) )} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. ' + 'Available breakpoints are: #{map-keys($breakpoints)}.';
  }
}

:root {
  --gray-1: #fff;
  --gray-1-5: #f9f9fa;
  --gray-2: #f8fafc;
  --gray-3: #f1f5f9;
  --gray-4: #e2e8f0;
  --gray-5: #cbd5e1;
  --gray-6: #94a3b8;
  --gray-7: #64748b;
  --gray-8: #475569;
  --gray-9: #0f172a;
  --gray-10: #000;
  --grey-1: #fff;
  --grey-1-5: #f9f9fa;
  --grey-2: #f8fafc;
  --grey-3: #f1f5f9;
  --grey-4: #e1e3e5;
  --grey-5: #cbd5e1;
  --grey-6: #94a3b8;
  --grey-7: #737585;
  --grey-8: #53576f;
  --grey-9: #262b4b;
  --grey-10: #050b30;
  --light-background-color: var(--gray-1);
  --background-color: var(--gray-3);
  --table-header-color: var(--gray-2);
  --divider-color: var(--gray-4);
  --border-color: var(--gray-5);
  --disabled-color: var(--gray-6);
  --secondary-text-color: var(--gray-7);
  --primary-text-color: var(--gray-8);
  --title-text-color: var(--gray-9);
  --ui-text-color: var(--gray-9);
  --blue-1: #e8e5fb;
  --blue-2: #d2ccf7;
  --blue-3: #a599ee;
  --blue-4: #7866e6;
  --blue-5: #3c2ffb;
  --blue-6: #1e00d5;
  --blue-7: #1800aa;
  --blue-8: #120080;
  --blue-9: #0f006b;
  --blue-10: #090040;
  --selected-background-color: var(--blue-1);
  --selected-border-color: var(--blue-3);
  --selected-hover-color: var(--blue-5);
  --selected-normal-color: var(--blue-6);
  --selected-click-color: var(--blue-7);
  --red-1: #fde9e8;
  --red-2: #f9beb8;
  --red-3: #f5948a;
  --red-4: #f2695c;
  --red-5: #ef3e31;
  --red-6: #d6261d;
  --red-7: #a61d16;
  --red-8: #77140e;
  --red-9: #470c07;
  --red-10: #180402;
  --error-background-color: var(--red-1);
  --error-border-color: var(--red-3);
  --error-hover-color: var(--red-4);
  --error-normal-color: var(--red-5);
  --error-click-color: var(--red-6);
  --green-1: #eafbf2;
  --green-2: #c0f3d9;
  --green-3: #96eabf;
  --green-4: #6de2a7;
  --green-5: #55dd97;
  --green-6: #2dd881;
  --green-7: #25965a;
  --green-8: #1a6b41;
  --green-9: #0f4127;
  --green-10: #05160d;
  --success-background: var(--green-1);
  --success-border: var(--green-3);
  --success-hover: var(--green-5);
  --success-normal: var(--green-6);
  --success-click: var(--green-7);
  --gold-1: #fff7e7;
  --gold-2: #fee5b6;
  --gold-3: #fdd487;
  --gold-4: #fdc359;
  --gold-5: #f0a202;
  --gold-6: #faad14;
  --gold-7: #b0771a;
  --gold-8: #7d5510;
  --gold-9: #4c3307;
  --gold-10: #191001;
  --warning-background: var(--gold-1);
  --warning-border: var(--gold-3);
  --warning-hover: var(--gold-5);
  --warning-normal: var(--gold-6);
  --warning-click: var(--gold-7);
  --cyan-1: #d6f8fc;
  --cyan-2: #c1f5fb;
  --cyan-3: #99eef8;
  --cyan-4: #70e8f6;
  --cyan-5: #5ce5f5;
  --cyan-6: #33def2;
  --cyan-7: #2ec8da;
  --cyan-8: #29b2c2;
  --cyan-9: #249caa;
  --cyan-10: #1a6f79;
  --cyan-colorful-tag-background: var(--cyan-1);
  --cyan-colorful-tag-border: var(--cyan-3);
  --cyan-colorful-tag-normal: var(--cyan-6);
  --volcano-1: #fff2e8;
  --volcano-2: #ffd8bf;
  --volcano-3: #ffbb96;
  --volcano-4: #ff9c6e;
  --volcano-5: #ff7a45;
  --volcano-6: #fa541c;
  --volcano-7: #d4380d;
  --volcano-8: #ad2102;
  --volcano-9: #871400;
  --volcano-10: #610b00;
  --volcano-colorful-tag-background: var(--volcano-1);
  --volcano-colorful-tag-border: var(--volcano-3);
  --volcano-colorful-tag-normal: var(--volcano-6);
  --orange-1: #fff7e6;
  --orange-2: #ffe7ba;
  --orange-3: #ffd591;
  --orange-4: #ffc069;
  --orange-5: #fa8c16;
  --orange-6: #fa8c16;
  --orange-7: #d46b08;
  --orange-8: #ad4e00;
  --orange-9: #873800;
  --orange-10: #612500;
  --orange-colorful-tag-background: var(--orange-1);
  --orange-colorful-tag-border: var(--orange-3);
  --orange-colorful-tag-normal: var(--orange-6);
  --yellow-1: #feffe6;
  --yellow-2: #fffb8f;
  --yellow-3: #ffffb8;
  --yellow-4: #fff566;
  --yellow-5: #ffec3d;
  --yellow-6: #fadb14;
  --yellow-7: #d4b106;
  --yellow-8: #ad8b00;
  --yellow-9: #876800;
  --yellow-10: #614700;
  --yellow-colorful-tag-background: var(--yellow-1);
  --yellow-colorful-tag-border: var(--yellow-3);
  --yellow-colorful-tag-normal: var(--yellow-6);
  --lime-1: #fcffe6;
  --lime-2: #f4ffb8;
  --lime-3: #eaff8f;
  --lime-4: #d3f261;
  --lime-5: #bae637;
  --lime-6: #a0d911;
  --lime-7: #7cb305;
  --lime-8: #5b8c00;
  --lime-9: #3f6600;
  --lime-10: #254000;
  --lime-colorful-tag-background: var(--lime-1);
  --lime-colorful-tag-border: var(--lime-3);
  --lime-colorful-tag-normal: var(--lime-6);
  --purple-1: #f9f0ff;
  --purple-2: #efdbff;
  --purple-3: #d3adf7;
  --purple-4: #b37feb;
  --purple-5: #9254de;
  --purple-6: #722ed1;
  --purple-7: #531dab;
  --purple-8: #391085;
  --purple-9: #22075e;
  --purple-10: #120338;
  --purple-colorful-tag-background: var(--purple-1);
  --purple-colorful-tag-border: var(--purple-3);
  --purple-colorful-tag-normal: var(--purple-6);
  --geekblue-1: #f0f5ff;
  --geekblue-2: #d6e4ff;
  --geekblue-3: #adc6ff;
  --geekblue-4: #85a5ff;
  --geekblue-5: #597ef7;
  --geekblue-6: #2f54eb;
  --geekblue-7: #1d39c4;
  --geekblue-8: #10239e;
  --geekblue-9: #061178;
  --geekblue-10: #030852;
  --geekblue-colorful-tag-background: var(--geekblue-1);
  --geekblue-colorful-tag-border: var(--geekblue-3);
  --geekblue-colorful-tag-normal: var(--geekblue-6);
  --magenta-1: #fff0f6;
  --magenta-2: #ffd6e7;
  --magenta-3: #ffadd2;
  --magenta-4: #ff85c0;
  --magenta-5: #f759ab;
  --magenta-6: #eb2f96;
  --magenta-7: #c41d7f;
  --magenta-8: #9e1068;
  --magenta-9: #780650;
  --magenta-10: #520339;
  --magenta-colorful-tag-background: var(--magenta-1);
  --magenta-colorful-tag-border: var(--magenta-3);
  --magenta-colorful-tag-normal: var(--magenta-6);
  --background-1: #f0f2f5;
  --background-2: #001529;
  --background-3: #000c17;
  --layout-background-color: var(--background-1);
  --menu-background-1-level: var(--background-2);
  --menu-background-2-level: var(--background-3);
  --primary-color: var(--blue-6);
  --gradient: linear-gradient(82.89deg, #33def2 5.55%, #1e7ad5 50%, #1e00d5 94.45%);
  --cyan-blue-1: var(--gradient);
  --special-button: var(--gradient);
  --box-shadow: 0 1px 2px rgb(16 24 40 / 5%);
  --box-shadow-dropdown: 0 4px 8px -2px rgb(16 24 40 / 10%), 0 2px 4px -2px rgb(16 24 40 / 6%);
  --box-shadow-full-dropdown: 0 25px 50px rgb(8 24 35 / 25%);
  --gradient-background: radial-gradient(circle at 100% 25%, #1e00d525, #fff1 50%), radial-gradient(at 0% 100%, #1e00d525, #fff2 50%);
  --tbd-header-height: 58px;
}

@import 'sailor-ui/styles/responsive';

body, body > #root, body > #root > div {
  height: 100%;
}

body {
  font-family: Inter , -apple-system,BlinkMacSystemFont,'Segoe UI,Roboto','Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
  margin: 0;
}

.ant-btn, .ant-input{
  border-radius: 4px;
}


.ant-input-focused, .ant-input:focus{
  border-color: var(--blue-5);
}

.ant-form-item-label> label{
  color: var(--gray-9);
}

.ant-steps-item-active .ant-steps-item-title{
  font-weight: 700;
}

.ant-tag-blue{
  color: var(--blue-6);
  border-radius: 15px;
  border-color: var(--blue-4);
  background-color: var(--blue-1);
}

.ant-tooltip-inner {
  color: var(--gray-8) !important;
  background-color: var(--gray-1) !important;
}

.ant-tooltip-arrow-content {
  --antd-arrow-background-color: var(--gray-1) !important;
}

.ant-avatar {
  color: var(--blue-6);
  background-color: var(--blue-1);
  border: 1px solid var(--blue-3);
}


.ant-table-cell{
  position: relative;

  .btnDetails {
    position: absolute;
    right: 10px;
    color: var(--blue-6);
    z-index: 2;
    background-color: transparent;
    opacity: 0;
    transition: all 100ms;
  }

  .ant-typography{
    margin: 0;
  }
}

.ant-table-thead .ant-table-cell {
  background-color: var(--gray-2);
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: var(--gray-9);
}

.ant-table-cell-row-hover {
      
  .btnDetails {
    color: var(--blue-6);
    opacity: 1;
    transition: all 100ms;
    background: rgb(255 254 254);
    background: linear-gradient(90deg, rgb(255 254 254 / 0%) 0%, rgb(250 250 250 / 100%) 10%, rgb(250 250 250 / 100%) 100%);
  }
}

@include responsive(xs){

  .ant-picker-datetime-panel {
    display: flex;
    flex-direction: column;
  }
}

.ant-tooltip{
  --antd-arrow-background-color: var(--gray-1);
}

.ant-tooltip-placement-top .ant-tooltip-arrow{
  --antd-arrow-background-color: var(--gray-1);
}



.ant-dropdown-class-name-item-option-content{
  display: flex;
  justify-content: space-between;
}


.ant-dropdown-class-name-selection-item{

  .ant-tag{
    display: none;
  }
}


.ant-btn-special.ant-btn{
  appearance: none;
  background: -webkit-gradient(to right,var(--cyan-6) 0%, #1e7ad5 10%, var(--blue-6) 20%,#1e7ad5 50%, var(--cyan-6) 70%,var(--blue-6) 100%);
  background: linear-gradient(to right,var(--cyan-6) 0%, #1e7ad5 10%, var(--blue-6) 20%,#1e7ad5 50%, var(--cyan-6) 70%,var(--blue-6) 100%);
  background-size: 500%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;

  &:hover{
    animation-name: gradient;
    animation-duration: 2s;
    animation-duration: s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    color: #fff !important;
  }
}

@keyframes gradient {

  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 100%;
  }
}

.ant-form-item-explain-error{
  color: var(--error-normal-color);
}
/* stylelint-disable selector-class-pattern */

/* stylelint-disable scss/at-import-partial-extension */
@import 'variables';
@import 'sliders';
@import './components/Header/index';
@import './components/antd_global';
@import '../components/TypeCard/TypeCard.style';
@import './components/ChecklistCarousel/slick-carousel';
@import '../components/Tooltip/Tooltip.style';

html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

#nprogress .bar{
  background: $primary;
}

#nprogress .spinner-icon {
  border-top-color: $primary;
  border-left-color: $primary;
}

* {
  box-sizing: border-box;
}

.single-public-boat-tooltip {
  z-index: 5;

  .ant-tooltip-content {

    .ant-tooltip-arrow {

      .ant-tooltip-arrow-content {
        background-color: rgb(30 0 213 / 90%);
      }
    }

    .ant-tooltip-inner {
      background-color: rgb(30 0 213 / 90%);
    }
  }
}

.not-in-db {
  color: rgb(224 30 30);
}


.ant-drawer .ant-drawer-content-wrapper .ant-drawer-body .ant-menu-item {
  justify-content: flex-start;
}



.ant-anchor-wrapper:not(.ant-anchor-wrapper-horizontal) .ant-anchor{

  &::before{
    border-inline-start: 0;
  }
}

.ant-menu .ant-menu-item .ant-menu-item-icon svg{

  &:hover{
    color: var(--gray-1);
  }
}

.ant-menu-submenu > .ant-menu .ant-menu-item .ant-menu-item-icon svg{
  width: 16px;
  height: 16px;
}


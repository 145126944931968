.TypeCard {
  height: 100%;
  box-shadow: 0 1px 2px rgba(8, 24, 35, 0.06), 0 1px 3px rgba(8, 24, 35, 0.1);
  border-radius: 4px;

  .ant-card-cover {
    margin: 16px 0;
    height: 120px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-card-meta-title {
    text-align: center;
  }

  &-LinkIcon {
    margin-left: 5px;
    transition: transform 0.2s;
  }

  &-Link {
    margin-top: 16px;
    text-align: center;
    color: $blue-6;
    display: block;

    &:hover {
      .TypeCard-LinkIcon {
        transition: transform 0.2s;
        transform: translateX(3px);
      }
    }
  }
}

.ExplorerByTypeColumn {
  margin-bottom: 0;
}

.ExplorerByTypeRow {
  align-items: stretch;
}

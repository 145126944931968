.home-cover-slider {
  position: relative;
  margin-top: 65px;

  // .slick-slide.slick-active {
  //   // margin: 0 15px;
  // }

  .slick-slide {
    transform: translate(0, 0) scale(0.9);
    transition: all 0.3s;
    opacity: 0.8;
    // width: 200px;
  }

  .slick-slide.slick-active.slick-center.slick-current {
    transition: all 0.3s;
    transform: scale(1);
    // width: 270px;
    margin: 0 15px;
    opacity: 1;
  }

  .slider-nav {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -185px;
    margin-top: -25px;
    // background-color: pink;
    width: 400px;
    z-index: 1;
    display: flex;
    justify-content: space-between;

    .ant-btn-text {
      height: 50px;
      font-size: 30px;
      color: #fff;
    }
  }
}

.BlogCarousel-Wrapper {
  .ant-carousel {
    .slick-track {
      display: flex;
      align-items: stretch;
    }

    .slick-slide {
      float: none;
      height: unset;
      margin-bottom: 5px;

      >div {
        height: 100%;

        >a {
          display: block;
          height: 100%;
        }
      }
    }
  }
}
